import React, {useContext} from 'react';
import {Route, Routes} from 'react-router-dom';
import {authRoutes} from "../routes";
import {Context} from "../index";
import Welcome from "../pages/Welcome";

const AppRouter = () => {
    const {user} = useContext(Context)
    return (
        <Routes>
            {user.isAuth === true && authRoutes.map(({path,Component}) =>
                <Route key={path} path={path} element={<Component/>} exact/>
            )}
            <Route path="*" element={<Welcome/>} />
        </Routes>
    );
};

export default AppRouter;