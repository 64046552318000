import React, {useContext} from 'react';
import {Button, Row} from "react-bootstrap";
import {Context} from "../index";
import {observer} from "mobx-react-lite";

const CategoryBar = observer(() => {
    const {products} = useContext(Context)

    return (
            <Row className="overflow-x-auto flex-nowrap">
                {products.categories.map(category => category.parent !== 0 &&
                    <Button
                        style = {{cursor: "pointer"}}
                        className="col m-1"
                        border={category.id === products.setSelectedMart.id ? 'danger':'light'}
                    >
                        {category.name}
                    </Button>
                )}
            </Row>

    );
});

export default CategoryBar;