import React, {useContext} from 'react';
import {Col, Figure} from "react-bootstrap";
import {useNavigate} from "react-router-dom"
import {Context} from "../index";


const CategoriesItem = ({category}) => {
    const {products} = useContext(Context)
    const navigate = useNavigate();

    return (
        <div>
            <Col
                key={category.id}
                onClick={() => {
                    products.setSelectedCategory(category);
                    navigate('/product/' + category.id);
                }}
            >
                <Figure>
                    <Figure.Image src="/images/cat_img.jpg"/>
                    <Figure.Caption>
                        {category.name}
                    </Figure.Caption>
                </Figure>
            </Col>
        </div>
    );
};

export default CategoriesItem;