import React from 'react';
import {
    ButtonGroup,
    Card,
    ToggleButton
} from "react-bootstrap";
import {observer} from "mobx-react-lite";

const ProductItem = observer(({product, basket, addBasketProduct, removeBasketProduct}) => {

    const addBasketProductHandler = (data) => {
        addBasketProduct(data);
    }

    const removeBasketProductHandler = (data) => {
        removeBasketProduct(data);
    }

    return (
        <Card border={'light'}>
            <Card.Img variant={'top'} src={"/images/cat_img.jpg"} alt={'...'}/>
            <Card.Body>
                <Card.Title>{product.name}</Card.Title>
                <Card.Text>{product.weight} {product.measure}</Card.Text>
                <Card.Text>{product.price}</Card.Text>
            </Card.Body>
            <ButtonGroup>
                {basket.filter(item => item.productId === product.id && item.quantity > 0).length > 0 ?
                    <>
                    {basket.map(basketItem => basketItem.productId === product.id &&
                            <>
                                <ToggleButton type={'checkbox'} variant={'success'}
                                              onClick={() => removeBasketProductHandler(basketItem)}>-</ToggleButton>
                                <ToggleButton type={'checkbox'}
                                              variant={'success'}>{basketItem.quantity} шт.</ToggleButton>
                                <ToggleButton type={'checkbox'}
                                              variant={'success'}>{basketItem.quantity * product.price}</ToggleButton>
                                <ToggleButton type={'checkbox'} variant={'success'}
                                              onClick={() => addBasketProductHandler(basketItem)}>+</ToggleButton>
                            </>
                    )}
                    </>
                    :
                    <>
                        <ToggleButton type={'checkbox'}
                                      variant={'light'}>{product.price}</ToggleButton>
                        <ToggleButton type={'checkbox'} variant={'light'}
                                      onClick={() => addBasketProductHandler({productId:product.id,quantity:0})}>+</ToggleButton>
                    </>
                }

            </ButtonGroup>
        </Card>
    );
});

export default ProductItem;