import {$authHost, $host} from "./index";
import { jwtDecode } from 'jwt-decode';

export const auth = async (telegramInitData) => {
    const {data} = await $host.post('user/auth', {telegramInitData})
    localStorage.setItem('token', data.token)
    return jwtDecode(data.token)
}

export const fetchUser = async () => {
    const {data} = await $authHost.get('user/')
    return data
}

export const updateUser = async (user) => {
    const {data} = await $authHost.put('user/', user)
    return data
}

export const fetchAddresses = async () => {
    const {data} = await $authHost.get('address/')
    return data
}

export const createAddresses = async (address) => {
    const {data} = await $authHost.post('address/',address)
    return data
}

export const updateAddress = async (address) => {
    const {data} = await $authHost.put('address/',address)
    return data
}

export const deleteAddress = async (id) => {
    const {data} = await $authHost.delete('address/',{params: {"id":id}})
    return data
}