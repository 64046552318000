import React, {useContext, useState} from 'react';
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import {Row} from "react-bootstrap";
import CategoriesItem from "./CategoriesItem";

const CategoriesList = observer(() => {
    const {products} = useContext(Context)

    return (
        <Row className={'row-cols-3 p-0'}>
            {products.categories.map(category => category.parent === 0 &&
                <CategoriesItem key={category.id} category={category} />
            )}
        </Row>
    );
});

export default CategoriesList;