import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {Button, Row} from "react-bootstrap";

const MartBar = observer(() => {
    const {products} = useContext(Context)

    return (
        <>
            <h6>Выберите магазин</h6>
            <Row className="overflow-x-auto flex-nowrap">
                {products.marts.map(mart =>
                    <Button
                        style = {{cursor: "pointer"}}
                        key={mart.id}
                        className="col-4 m-1"
                        onClick={() => products.setSelectedMart(mart)}
                        border={mart.id === products.setSelectedMart.id ? 'danger':'light'}
                    >
                        {mart.name}
                    </Button>
                )}
            </Row>
        </>

    );
});

export default MartBar;