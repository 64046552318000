import Admin from "./pages/Admin";
import {
    ABOUT_ROUTE, ADDRESS_ROUTE,
    ADMIN_ROUTE,
    BASKET_ROUTE,
    HISTORY_ROUTE,
    PERSONAL_PAGE_ROUTE,
    PRODUCT_ROUTE,
    SHOP_ROUTE
} from "./utils/consts";
import Basket from "./pages/Basket";
import Shop from "./pages/Shop";
import ProductPage from "./pages/ProductPage";
import History from "./pages/History";
import About from "./pages/About";
import PersonalPage from "./pages/PersonalPage";
import Address from "./pages/Address";

export const authRoutes = [
    {
        path: ADMIN_ROUTE,
        Component: Admin
    },
    {
        path: BASKET_ROUTE,
        Component: Basket
    },
    {
        path: SHOP_ROUTE,
        Component: Shop
    },
    {
        path: PRODUCT_ROUTE + '/:id',
        Component: ProductPage
    },
    {
        path: HISTORY_ROUTE,
        Component: History
    },
    {
        path: ABOUT_ROUTE,
        Component: About
    },
    {
        path: PERSONAL_PAGE_ROUTE,
        Component: PersonalPage
    },
    {
        path: ADDRESS_ROUTE,
        Component: Address
    },
    {
        path: "*",
        Component: Shop
    },
]