import React, {useContext, useEffect, useState} from 'react';
import {Alert, Button, Col, Container, Image, InputGroup, Offcanvas, Row} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import {Context} from "../index";
import {useNavigate} from "react-router-dom";
import {updateUser} from "../https/userAPI";
let tg = window.Telegram.WebApp;

const PersonalPage = () => {
    const {user} = useContext(Context)
    const navigate = useNavigate();
    const [phoneShow, setPhoneShow] = useState(false);
    const handlePhoneClose = () => setPhoneShow(false);
    const handlePhoneShow = () => setPhoneShow(true);

    const [phone, setPhone] = useState(user.userPhone)

    const [validation, setValidation] = useState(false);

    const phoneHandler = (e) =>{
        const input = e.target.value;
        const filter = /^[0-9\b]+$/;
        if (input === '' || input.match(filter) !== null){
            setPhone(input)
        }
    }

    useEffect(() => {
        if (validation === true) {
            setTimeout(() => {
            setValidation(false)
        }, 1000);}

    }, [validation]);

    const savePhone = () => {
        if (phone !== null && phone.length === 10) {
            updateUser({"phone_number": phone}).then(()=>{
                user.setUserPhone(phone)
                handlePhoneClose()
            })
        }else{
            setValidation(true)
        }
    }

    return (
        <Container>
            <Row>
                <Col className={'col-3'}><Image className={'img-thumbnail rounded-circle'}
                                                src={tg.initDataUnsafe.user?.photo_url}></Image></Col>
                <Col className={'col-6 align-self-center'}>
                    <div className={'col'}>@{tg.initDataUnsafe.user?.username}</div>
                    <div className={'col'}>{tg.initDataUnsafe.user?.first_name}</div>
                </Col>
            </Row>
            <ul className="list-group list-group-flush mt-2">
                <li
                    className="list-group-item"
                    onClick={handlePhoneShow}
                >
                    <Row>
                        <Col className={'col-11'}>
                            <Row>
                                <h4>Номер телефона</h4>
                            </Row>
                            <Row>
                                {user.userPhone !== null ?
                                    <h6>+7{user.userPhone}</h6>
                                    :
                                    <h6>Еще не добавлено</h6>
                                }
                            </Row>
                        </Col>
                        <Col className={'col-1 d-flex align-items-center'}>
                            >
                        </Col>
                    </Row>
                </li>
                <li
                    className="list-group-item"
                    onClick={() => {navigate('/address')}}
                >
                    <Row className={'mt-2'}>
                        <Col className={'col-11'}>
                            <h4>Ваши адреса</h4>
                        </Col>
                        <Col className={'col-1 d-flex align-items-center'}>
                            >
                        </Col>
                    </Row>
                </li>
            </ul>

            <Offcanvas
                show={phoneShow}
                onHide={handlePhoneClose}
                placement={'bottom'}
            >
                <Offcanvas.Body>
                    <Form.Label>Введите номер телефона</Form.Label>
                    <InputGroup hasValidation className="mb-3">
                        <InputGroup.Text>+7</InputGroup.Text>
                        <Form.Control
                            placeholder="Введите номер телефона"
                            value={phone}
                            type={'tel'}
                            minLength={10}
                            maxLength={10}
                            onChange={e=>{phoneHandler(e)}}
                            isInvalid={validation}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            <h6>Некорректный номер телефона</h6>
                        </Form.Control.Feedback>
                    </InputGroup>
                    <Button onClick={savePhone}>Сохранить</Button>
                </Offcanvas.Body>
            </Offcanvas>
        </Container>
    );
};

export default PersonalPage;