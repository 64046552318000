import {makeAutoObservable} from "mobx";

export default class ProductStore {
    constructor() {
        this._categories = []
        this._marts = []
        this._products = []
        this._basket = []
        this._selectedMart = {}
        this._selectedCategory = {}
        makeAutoObservable(this)
    }

    setCategories(categories) {
        this._categories = categories
    }
    setMarts(marts) {
        this._marts = marts
    }
    setProducts(products) {
        this._products = products
    }
    setBasket(basket) {
        this._basket = basket
    }
    setSelectedMart(mart) {
        this._selectedMart = mart
    }
    setSelectedCategory(category) {
        this._selectedCategory = category
    }

    get categories() {
        return this._categories
    }
    get marts() {
        return this._marts
    }
    get products() {
        return this._products
    }
    get basket() {
        return this._basket
    }
    get selectedMart() {
        return this._selectedMart
    }
    get selectedCategory() {
        return this._selectedCategory
    }
}