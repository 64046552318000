import React, {useContext, useEffect, useState} from 'react';
import {Alert, Col, Row} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {Context} from "../index";

const BasketBar = observer(() => {
    const {products} = useContext(Context)
    const [show, setShow] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        if (products.selectedMart.id !== undefined && products.basket.filter(item => (item.quantity > 0)&&(item.product.martId === products.selectedMart.id)).length > 0) {
            setShow(true)
        }else{
            setShow(false)
        }
    }, [products.selectedMart]);

    useEffect(() => {
        let tempPrice = 0
        let tempCount = 0

        if (products.basket.filter(item => (item.quantity > 0)&&(item.product.martId === products.selectedMart.id)).length > 0) {

            if (!show){
                setShow(true)
            }
            products.basket.filter(basketItem => basketItem.quantity > 0 &&(basketItem.product.martId === products.selectedMart.id)).map(item => {
                tempCount += item.quantity;
                tempPrice += item.quantity * item.product.price
            })
            setTotalCount(tempCount)
            setTotalPrice(tempPrice)
        }else{
            setTotalCount(0)
            setTotalPrice(0)
            if (show){
                setShow(false)
            }
        }
    }, [products.selectedMart,products.basket]);

    return (
        <Row className="sticky-bottom ms-3 me-3 mb-2">
            <Alert variant={'dark'} className={'rounded-4 '} show={show}>
                <Row>
                    <Col>{totalCount} товара</Col>
                    <Col>Итог: {totalPrice} Р </Col>
                </Row>
            </Alert>
        </Row>
    );
});

export default BasketBar;