import {$authHost} from "./index";

export const createMart = async (type) => {
    const {data} = await $authHost.post('mart/', type)
    return data
}

export const fetchMart = async () => {
    const {data} = await $authHost.get('mart/')
    return data
}

export const fetchCategory = async (martId) => {
    if (!martId){martId = ""}
    const {data} = await $authHost.get('category/', {params: {
            martId
        }})
    return data
}

export const fetchProducts = async (categoryId,martId) => {
    const {data} = await $authHost.get('product/', {params: {
            categoryId,
            martId
        }})
    return data
}

export const fetchBasket = async () => {
    const {data} = await $authHost.get('basket/', )
    return data
}

export const editBasket = async (basket) => {
    const {data} = await $authHost.put('basket/',basket)
    return data
}