import {makeAutoObservable} from "mobx";

export default class UserStore {
    constructor() {
        this._isAuth = false
        this._user = []
        this._userPhone = ""
        this._userMainAddress = []
        this._addresses = []
        this._addressesCount = null
        makeAutoObservable(this)
    }

    setIsAuth(bool) {
        this._isAuth = bool
    }
    setUser(user) {
        this._user = user
    }
    setUserPhone(userPhone) {
        this._userPhone = userPhone
    }
    setUserMainAddress(mainAddress) {
        this._userMainAddress = mainAddress
    }
    setAddresses(addresses) {
        this._addresses = addresses
    }
    setAddressesCount(addressesCount) {
        this._addressesCount = addressesCount
    }
    get isAuth() {
        return this._isAuth
    }
    get user() {
        return this._user
    }
    get userPhone() {
        return this._userPhone
    }
    get userMainAddress() {
        return this._userMainAddress
    }
    get addresses() {
        return this._addresses
    }
    get addressesCount() {
        return this._addressesCount
    }
}