import React, {useContext, useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import {editBasket, fetchBasket, fetchProducts} from "../https/productAPI";
import {Context} from "../index";
import {Row} from "react-bootstrap";
import ProductItem from "./ProductItem";
import {observer} from "mobx-react-lite";

const ProductList = observer(() => {
    const {products} = useContext(Context)
    const {id} = useParams()

    const [basket, setBasket] = useState(products.basket)

    useEffect(() => {
        fetchProducts(id, products.selectedMart.id).then(data => {
            products.setProducts(data)
        })
        fetchBasket(products.selectedMart.id).then(data => {
            products.setBasket(data)
            setBasket(data)
        })
    }, [])

    const addBasketProduct = (data) => {
        data.quantity = data.quantity + 1
        editBasket(data).then(result => {
            products.setBasket(result)
            setBasket(result)
        })
    }

    const removeBasketProduct = (data) => {
        data.quantity = data.quantity - 1
        editBasket(data).then(result => {
            products.setBasket(result)
            setBasket(result)
        })
    }

    return (
        <>
            {products.categories.map(category => category.parent === products.selectedCategory.id &&
                <>
                    <Row>
                        <h6>{category.name}</h6>
                    </Row>
                    <Row className={'row-cols-2'}>
                        {products.products.map(product => product.categoryId === category.id &&
                            <ProductItem
                                key={product.id}
                                product={product}
                                basket={basket}
                                addBasketProduct = {addBasketProduct}
                                removeBasketProduct = {removeBasketProduct}
                            />
                        )}
                    </Row>
                </>
            )}
        </>
    );
});

export default ProductList;