import React, {useContext, useEffect, useState} from "react";
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import NavBar from "./components/NavBar";
import {Context} from "./index";
import {auth, fetchAddresses, fetchUser} from "./https/userAPI";
import {Spinner} from "react-bootstrap";
import {observer} from "mobx-react-lite";

let tg = window.Telegram.WebApp;

const App = observer(() => {
    const {user} = useContext(Context)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        auth(tg.initData)
            .then(data => {
                user.setIsAuth(true)
                fetchUser()
                    .then(data => {
                        user.id = data.id;
                        user.setUserPhone(data.phone_number);
                        user.setUserMainAddress(data.mainAddress);
                    })
                fetchAddresses()
                    .then(data => {
                        user.setAddresses(data.data)
                        user.setAddressesCount(data.count)
                    })
            }).finally(() => setLoading(false))
    }, [])

    if (loading) {
        return <Spinner animation={"grow"}/>
    }

    return (
        <BrowserRouter>
            <NavBar/>
            <AppRouter/>
        </BrowserRouter>
    );
});

export default App;