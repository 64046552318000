import React from 'react';
import {Container} from "react-bootstrap";
import CategoryBar from "../components/CategoryBar";
import ProductList from "../components/ProductList";
import BasketBar from "../components/BasketBar";

const ProductPage = () => {

    return (
        <Container>
            <CategoryBar/>
            <ProductList/>
            <BasketBar/>
        </Container>
    );
};

export default ProductPage;