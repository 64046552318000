import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, ListGroup, Row, Form, Button, Offcanvas} from "react-bootstrap";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import {createAddresses, deleteAddress, fetchAddresses, updateAddress, updateUser} from "../https/userAPI";

const Address = observer(() => {
    const {user} = useContext(Context)

    const [currentAddress, setCurrentAddress] = useState(user.userMainAddress);
    const [addAddressShow, setAddAddressShow] = useState(false);
    const handleAddAddressHide = () => setAddAddressShow(false);
    const handleAddAddressShow = () => setAddAddressShow(true);

    const clearFields = () => {
        setSelectedAddress(null)
        setStreet('')
        setApartment('')
        setNumber('')
        setEntrance('')
    }

    useEffect(() => {
        clearFields()
    }, [addAddressShow]);

    const [editAddressShow, setEditAddressShow] = useState(false);
    const handleEditAddressHide = () => setEditAddressShow(false);
    const handleEditAddressShow = () => setEditAddressShow(true);

    const [selectedAddress, setSelectedAddress] = useState(null);
    const [street, setStreet] = useState('')
    const [apartment, setApartment] = useState('')
    const [number, setNumber] = useState('')
    const [entrance, setEntrance] = useState('')

    const currentAddressHandler = (id) => {
        updateUser({"mainAddress": id}).then(() => {
            setCurrentAddress(id)
        })
    }

    const addAddress = () => {
        createAddresses({street:street, apartment:apartment, number:number, entrance:entrance}).then(() => {
            fetchAddresses()
                .then(data => {
                    user.setAddresses(data.data)
                    user.setAddressesCount(data.count)
                })
            handleAddAddressHide()
        })
    }

    const editAddressHandler = (address) => {
        setSelectedAddress(address.id)
        setStreet(address.street)
        setApartment(address.apartment)
        setNumber(address.number)
        setEntrance(address.entrance)
        handleEditAddressShow()
    }

    const editAddress = () => {
        updateAddress({
            'id': selectedAddress,
            'street': street,
            'apartment': apartment,
            'number': number,
            'entrance': entrance
        }).then(() => {
                fetchAddresses()
                    .then(data => {
                        user.setAddresses(data.data)
                        user.setAddressesCount(data.count)
                    })
                clearFields()
                handleEditAddressHide()
            }
        )
    }

    const killAddress = () => {
        deleteAddress(selectedAddress).then(() => {
            fetchAddresses()
                .then(data => {
                    user.setAddresses(data.data)
                    user.setAddressesCount(data.count)
                })
            clearFields()
            handleEditAddressHide()
        })
    }

    return (
        <Container>
            <ListGroup variant="flush">
                {user.addressesCount === 0 &&
                    <h4>чтобы добавить адрес нажмите кнопку ниже</h4>
                }
                {user.addressesCount !== 0 && user.addresses.map(address =>
                    <ListGroup.Item key={address.id}>
                        <Row>
                            <Col onClick={()=>editAddressHandler(address)} className={'col-10'}>
                                <Row>
                                    <h4>ул. {address.street}, дом {address.apartment}</h4>
                                </Row>
                                <Row>
                                    <h6>кв. {address.number}, подъезд {address.entrance}</h6>
                                </Row>
                            </Col>
                            <Col className={'col-2 d-flex align-items-center'}>
                                <Form.Check
                                    type={'radio'}
                                    onChange={() => currentAddressHandler(address.id)}
                                    name="current address"
                                    id={address.id}
                                    checked={address.id === currentAddress}
                                />
                            </Col>
                        </Row>
                    </ListGroup.Item>
                )}
            </ListGroup>

            <Button onClick={handleAddAddressShow}>+ Добавить адрес</Button>

            <Offcanvas
                show={addAddressShow}
                onHide={handleAddAddressHide}
                placement={'bottom'}
                className={'h-75'}
            >
                <Offcanvas.Body>
                    <h4>Добавление адреса</h4>
                    <Form>
                        <Form.Control
                            placeholder="Введите название улицы"
                            value={street}
                            type={'text'}
                            onChange={e=> setStreet(e.target.value)}
                            className={'mt-2'}
                            required
                        />
                        <Form.Control
                            placeholder="Введите номер дома"
                            type={'text'}
                            value={apartment}
                            onChange={e => setApartment(e.target.value)}
                            className={'mt-2'}
                            required
                        />
                        <Form.Control
                            placeholder="Введите номер квартиры"
                            type={'number'}
                            value={number}
                            onChange={e => setNumber(e.target.value)}
                            className={'mt-2'}
                            required
                        />
                        <Form.Control
                            placeholder="Введите номер подъезда"
                            value={entrance}
                            onChange={e => setEntrance(e.target.value)}
                            type={'number'}
                            className={'mt-2'}
                            required
                        />
                        <Button className={'mt-2'} onClick={addAddress}>Сохранить</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas
                id={'edit-address'}
                show={editAddressShow}
                onHide={handleEditAddressHide}
                placement={'bottom'}
                className={'h-75'}
            >
                <Offcanvas.Body>
                    <h4>Изменение адреса</h4>
                    <Form>
                        <Form.Control
                            placeholder="Введите название улицы"
                            value={street}
                            type={'text'}
                            onChange={e => setStreet(e.target.value)}
                            className={'mt-2'}
                            required
                        />
                        <Form.Control
                            placeholder="Введите номер дома"
                            type={'text'}
                            value={apartment}
                            onChange={e => setApartment(e.target.value)}
                            className={'mt-2'}
                            required
                        />
                        <Form.Control
                            placeholder="Введите номер квартиры"
                            type={'text'}
                            value={number}
                            onChange={e => setNumber(e.target.value)}
                            className={'mt-2'}
                            required
                        />
                        <Form.Control
                            placeholder="Введите номер подъезда"
                            value={entrance}
                            onChange={e => setEntrance(e.target.value)}
                            type={'text'}
                            className={'mt-2'}
                            required
                        />
                        <Button className={'mt-2'} onClick={killAddress}>Удалить</Button>
                        <Button className={'mt-2'} onClick={editAddress}>Сохранить</Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

        </Container>
    );
});

export default Address;