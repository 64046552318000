import React, {useContext, useEffect} from 'react';
import {Container} from "react-bootstrap";
import MartBar from "../components/MartBar";
import CategoriesList from "../components/CategoriesList";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {fetchBasket, fetchCategory, fetchMart} from "../https/productAPI";
import AddressBar from "../components/AddressBar";
import MartList from "../components/MartList";
import BasketBar from "../components/BasketBar";

const Shop = observer( () => {
    const {user} = useContext(Context)
    const {products} = useContext(Context)

    useEffect(() => {
        fetchMart().then(data => products.setMarts(data))
    }, [])

    useEffect(() => {
        if (products.selectedMart.id !== undefined){
            fetchCategory(products.selectedMart.id).then(data => {
                products.setCategories(data)
            })
            fetchBasket(products.selectedMart.id).then(data => {
                products.setBasket(data)
            })
        }
    }, [products.selectedMart])

    return (
        <>
            <Container className={'min-vh-100'}>
                {products.selectedMart.id === undefined ?
                    <>
                        {user.addressesCount === 0 &&
                            <AddressBar/>
                        }
                        <MartList/>
                    </>
                    :
                    <>
                        <MartBar/>
                        {user.addressesCount === 0 &&
                            <AddressBar/>
                        }
                    </>
                }
                {products.selectedMart.id !== undefined &&
                    <CategoriesList/>
                }
            </Container>
            <BasketBar/>
        </>
    );
});

export default Shop;