import React, {useContext, useState} from 'react';
import {Context} from "../index";
import {Image, Button, Col, Container, Offcanvas, Row} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";

let tg = window.Telegram.WebApp;

const NavBar = observer(() => {
    const navigate = useNavigate();
    const {user} = useContext(Context)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if (user.isAuth === false) {
        return ""
    }

    return (
        <Container className={'pt-2 pb-2 sticky-top bg-light'}>
            <Row>
                {window.location.pathname === '/' ?
                    <Col onClick={() => navigate('/')} className={'align-self-center'}><img src='/logo.svg'
                                                                                            className={"img-fluid p-2"}
                                                                                            alt="..."/></Col>
                    :
                    <Col onClick={() => navigate(-1)} className={'align-self-center'}><img src='/icons/back.svg'
                                                                                           className={"img-fluid p-2"}
                                                                                           alt="..."/> Назад</Col>
                }

                <Col className={'text-end'}>
                    <Button variant="light" onClick={handleShow}>
                        <img alt="" src='/icons/burger.svg'/>
                    </Button>

                    <Offcanvas show={show} onHide={handleClose} placement={'end'} backdrop={'static'}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Меню</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Row>
                                <Col className={'col-3'}><Image className={'img-thumbnail rounded-circle'}
                                                                src={tg.initDataUnsafe.user?.photo_url}></Image></Col>
                                <Col className={'col-6 align-self-center'}>
                                    <div className={'col'}>@{tg.initDataUnsafe.user?.username}</div>
                                    <div className={'col'}>{tg.initDataUnsafe.user?.first_name}</div>
                                </Col>
                            </Row>

                            <Row
                                className={'mt-2'}
                                onClick={() => {
                                    navigate('/')
                                    handleClose()
                                }}
                            >
                                <Col className={'col-2 align-self-center text-center'}><img alt=""
                                                                                            src='/icons/store.svg'/></Col>
                                <Col className={'p-2'}>Магазин</Col>
                            </Row>

                            <Row
                                onClick={() => {
                                    navigate('/story')
                                    handleClose()
                                }}
                            >
                                <Col className={'col-2 align-self-center text-center'}><img alt=""
                                                                                            src='/icons/story.svg'/></Col>
                                <Col className={'p-2'}>История</Col>
                            </Row>
                            <Row
                                onClick={() => {
                                    navigate('/personal_page')
                                    handleClose()
                                }}
                            >
                                <Col className={'col-2 align-self-center text-center'}><img alt=""
                                                                                            src='/icons/user_data.svg'/></Col>
                                <Col className={'p-2'}>Ваши данные</Col>
                            </Row>
                            <Row
                                onClick={() => {
                                    navigate('/about')
                                    handleClose()
                                }}
                            >
                                <Col className={'col-2 align-self-center text-center'}><img alt=""
                                                                                            src='/icons/about.svg'/></Col>
                                <Col className={'p-2'}>О проекте</Col>
                            </Row>
                        </Offcanvas.Body>
                    </Offcanvas>
                </Col>
            </Row>
        </Container>
    );
});

export default NavBar;